import React, { useEffect } from "react";

import { Button, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getTelematicsListAdvancedFilter,
  getTelematicsListTextFilter,
  selectIsAggregated,
} from "../../selectors/telematicsList.selectors";
import { selectDateRange } from "../../selectors/telematicsTabs.selectors";

import { setAdvancedFilter } from "../../../shared/actions/filter.actions";
import { aggregatedSet } from "../../actions/telematicsList.actions";
import { setDateRange } from "../../actions/telematicsTabs.actions";

import { TELEMATICS } from "../../../core/map/constants/contexts.constants";
import { DUPLICATED_TAB_KEY } from "../../../core/map/constants/localStorage.constants";
import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";

import { NAMESPACE as namespace } from "../../reducer/telematicsList.reducer";

import { MachineTo } from "../../../generated/api/telematics";
import { MAP_CONTEXT_LS_KEY } from "../../../saga/mainMap.saga";
import { CfSwitch } from "../../../shared/components/common/CfSwitch/CfSwitch";
import CfTextFilter from "../../../shared/containers/CfTextFilter/CfTextFilter";
import LocalStorage from "../../../shared/services/LocalStorage.service";
import { Thunk } from "../../../types";
import { duplicateTabHandler } from "../../helpers";
import { TelematicsNgProps } from "../Telematics/Telematics";
import TelematicsAdvancedFilter from "../TelematicsAdvancedFilter/TelematicsAdvancedFilter";

import TelematicsTable from "./TelematicsTable/TelematicsTable";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  initDateFrom: string;
  initDateTo: string;
  initMachineFilter?: MachineTo;
  langId: LANGUAGE_ID;
  ngResetFilters: () => void;
  setInitMachineFilter: (initMachineFilter?: MachineTo) => void;
};
type Props = ReduxProps & OwnProps & TelematicsNgProps;

const _TelematicsLogbook = ({
  advancedFilter,
  dateFilter,
  initDateFrom,
  initDateTo,
  initMachineFilter,
  isAggregated,
  langId,
  ngRedirectToMainMapWithFilters,
  ngResetFilters,
  setAdvancedFilter,
  setAggregated,
  setDateRange,
  setInitMachineFilter,
  textFilter,
}: Props) => {
  const classes = useStyles();

  const handleShowOnMapClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    LocalStorage.saveToLocalStorage(TELEMATICS, MAP_CONTEXT_LS_KEY);
    const openInNewTab = duplicateTabHandler({
      driver: advancedFilter?.driver,
      machine: advancedFilter?.machine,
      ...dateFilter,
    })(e);

    ngRedirectToMainMapWithFilters(
      dateFilter.dateFrom,
      dateFilter.dateTo,
      advancedFilter?.machine,
      advancedFilter?.driver,
      openInNewTab,
    );
  };

  useEffect(() => {
    if (initDateFrom && initDateTo) {
      setDateRange(initDateFrom, initDateTo);
    }
  }, [initDateFrom, initDateTo, setDateRange]);

  useEffect(() => {
    const ls = LocalStorage.loadFromLocalStorage(DUPLICATED_TAB_KEY);
    if (ls?.duplicatedTab?.filters) {
      const { dateFrom, dateTo, driver, machine } = ls.duplicatedTab.filters;
      setDateRange(dateFrom, dateTo);
      if (driver) {
        setAdvancedFilter(
          { driver: [{ code: driver.code, name: driver.name, validFrom: "" }] },
          namespace,
        );
      } else if (machine) {
        setAdvancedFilter(
          {
            machine: [
              {
                machineCode: machine.code,
                name: machine.name,
                validFrom: "",
                gpsUnit: machine.gpsUnit,
                group: machine.group,
                category: machine.category,
              },
            ],
          },
          namespace,
        );
      }

      LocalStorage.removeFromLocalStorage(DUPLICATED_TAB_KEY);
    }
  }, [setAdvancedFilter, setDateRange]);

  const isMachineOrDriverFilterSelected = !!(
    advancedFilter?.machine || advancedFilter?.driver
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.filters}>
        <div className={classes.textFilter}>
          <CfTextFilter
            initialValue={textFilter}
            name="telematics-list-text-filter"
            namespace={namespace}
            translId="TelematicsList.textFilterPlaceholder"
          />
        </div>

        <div className={classes.advancedFilter}>
          <TelematicsAdvancedFilter
            initMachineFilter={initMachineFilter}
            langId={langId}
            namespace={namespace}
            ngResetFilters={ngResetFilters}
            setInitMachineFilter={setInitMachineFilter}
          />
        </div>
        {isMachineOrDriverFilterSelected && (
          <div>
            <Button
              className={classes.buttonToMap}
              data-test="show-in-map"
              onClick={handleShowOnMapClick}
              onMouseDown={handleShowOnMapClick}
              size="small"
              variant="contained"
            >
              <FormattedMessage id="TelematicsList.buttonToMap" />
            </Button>
          </div>
        )}
        <CfSwitch
          initialChecked={isAggregated}
          label={<FormattedMessage id="TelematicsList.aggregateRecords" />}
          onChange={setAggregated}
          sx={{ marginLeft: "auto" }}
        />
      </div>
      <TelematicsTable />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  filters: {
    display: "flex",
    paddingBottom: theme.spacing(1),
    gap: theme.spacing(1.5),
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "auto auto min-content",
      gridTemplateAreas: `
        'text-filter text-filter text-filter'
        'date . filter toggle'
      `,
    },
  },
  listActionsItem: {
    gridArea: "actions",
  },
  date: {
    gridArea: "date",
    display: "flex",
  },
  textFilter: {
    gridArea: "text-filter",
    maxWidth: 500,
    flexGrow: 1,
    [theme.breakpoints.down("md")]: {
      maxWidth: "none",
      width: "100%",
    },
  },
  advancedFilter: {
    gridArea: "filter",
  },
  buttonToMap: {
    borderRadius: "50px",
    color: theme.palette.common.black,
  },
}));

const mapStateToProps = (state: TelematicsState) => ({
  advancedFilter: getTelematicsListAdvancedFilter(state),
  dateFilter: selectDateRange(state),
  isAggregated: selectIsAggregated(state),
  textFilter: getTelematicsListTextFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      setDateRange,
      setAdvancedFilter,
      setAggregated: aggregatedSet,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
const TelematicsLogbook = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_TelematicsLogbook);

export { TelematicsLogbook };
