import React, { useEffect } from "react";

import TableBody from "@mui/material/TableBody";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getTelematicsListAdvancedFilter,
  getTelematicsListOrder,
  getTelematicsListOrderBy,
  getTelematicsListPage,
  getTelematicsListRowsPerPage,
  getTelematicsListTextFilter,
  selectIsAggregated,
} from "../../../selectors/telematicsList.selectors";
import { selectDateRange } from "../../../selectors/telematicsTabs.selectors";

import { setFocusedRow } from "../../../../shared/api/telematics/drives/drives.actions";

import { NAMESPACE as namespace } from "../../../reducer/telematicsList.reducer";

import { ColumnData } from "../../../../catalogues/helpers/tableHelpers";
import {
  AggregatedRecordsParams,
  LogbookAggregatedItemTo,
  LogbookItemTo,
  LogbookParams,
  State,
} from "../../../../generated/api/telematics";
import { useFarmIds } from "../../../../shared/api/client.utils";
import CfErrorPage from "../../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import CfTableBodyEmpty from "../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableWrapper from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../../shared/containers/CfTableHead/CfTableHead";
import { getColDesc } from "../../../../shared/misc/helper";
import DoubleLinedHeader from "../../../components/DoubleLinedHeader/DoubleLinedHeader";
import { getDriveBadgeProps } from "../../../helpers";
import { logbookQuery } from "../TelematicsLogbook.api";

import RecordRow from "./RecordRow";

const TelematicsTable = () => {
  const dispatch = useDispatch();
  const page = useSelector(getTelematicsListPage);
  const order = useSelector(getTelematicsListOrder);
  const orderBy = useSelector(getTelematicsListOrderBy);
  const rowsPerPage = useSelector(getTelematicsListRowsPerPage);
  const isAggregated = useSelector(selectIsAggregated);
  const search = useSelector(getTelematicsListTextFilter);
  const filter = useSelector(getTelematicsListAdvancedFilter);
  const dateRange = useSelector(selectDateRange);
  const intl = useIntl();
  const params: (AggregatedRecordsParams | LogbookParams) & {
    aggregated: boolean;
  } = {
    ...useFarmIds(),
    dateFrom: dateRange.dateFrom,
    dateTo: dateRange.dateTo,
    // @ts-expect-error swagger is prolly a bit off
    gpsUnit: filter.machine?.map((m) => m.gpsUnit).join(","),
    // @ts-expect-error swagger is prolly a bit off
    affiliation: filter.parcelAffiliation?.join(","),
    search,
    // @ts-expect-error swagger is prolly a bit off
    state: filter.state?.join(","),
    // @ts-expect-error swagger is prolly a bit off
    crop: filter.crop?.map((c) => c.code).join(","),
    // @ts-expect-error swagger is prolly a bit off
    driver: filter.driver?.map((d) => d.code ?? "").join(","),
    // @ts-expect-error swagger is prolly a bit off
    parcel: filter.parcel?.map((p) => p.id).join(","),
    // @ts-expect-error swagger is prolly a bit off
    equipment: filter.equipment?.map((e) => e.code ?? "").join(","),
    // @ts-expect-error swagger is prolly a bit off
    operation: filter.operation?.join(","),
    // @ts-expect-error swagger is prolly a bit off
    productionOperation: filter.productionOperation
      ?.map((po) => po.code?.toString() ?? "")
      .join(","),
    // @ts-expect-error swagger is prolly a bit off
    parcelSubjectId: filter.parcelSubject?.map((ps) => ps.id).join(","),
    page: page + 1,
    "per-page": rowsPerPage,
    "sort-col": orderBy,
    "sort-dir": order,
  };
  const drives = useQuery({
    ...logbookQuery({ ...params, aggregated: isAggregated }),
  });
  let columns: Record<string, ColumnData>;

  useEffect(
    () => () => {
      dispatch(setFocusedRow(undefined));
    },
    [dispatch],
  );

  const mapDrive = (drive: LogbookAggregatedItemTo | LogbookItemTo) => {
    let operationTooltip = intl.formatMessage({
      id: "TelematicsList.unApprovedRide",
    });

    if (drive.state === State.DEFERRED) {
      operationTooltip = intl.formatMessage({
        id: "TelematicsList.postponedRide",
      });
    }

    if (drive.state !== State.NOT_APPROVED) {
      operationTooltip = intl.formatMessage({
        id: "TelematicsList.approvedRide",
      });
    }
    const badgeProps = getDriveBadgeProps(drive.type, intl);

    return {
      ...drive,
      operationTooltip,
      badgeProps,
    };
  };

  if (drives.status === "pending") {
    return <CfLoader />;
  }

  if (drives.status === "error") {
    return <CfErrorPage error={drives.error} />;
  }

  if (isAggregated) {
    columns = { ...durationColumn, ...commonColumns };
  } else {
    columns = { ...timeColumn, ...commonColumns };
  }

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        items={drives.data.data}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
      />
      <TableBody>
        {drives.data.data.map(mapDrive).map((drive) => (
          <RecordRow drive={drive} isInit key={drive.id} />
        ))}
      </TableBody>
      {drives.data.data.length === 0 ? (
        <CfTableBodyEmpty colLength={Object.keys(commonColumns).length + 1} />
      ) : null}

      <CfTableFooter
        count={drives.data.count}
        namespace={namespace}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const commonColumns = {
  "driver.name": getColDesc(
    true,
    <DoubleLinedHeader
      primaryId="TelematicsList.driverName"
      secondaryId="TelematicsList.chipId"
    />,
  ),
  operation: getColDesc(
    false,
    <FormattedMessage id="TelematicsList.operation" />,
  ),
  "productionOperation.name": getColDesc(
    true,
    <FormattedMessage id="TelematicsList.productionOperation" />,
  ),
  parcelName: getColDesc(
    false,
    <DoubleLinedHeader
      primaryId="TelematicsList.parcelName"
      secondaryId="TelematicsList.crop"
    />,
  ),
  cultivated: getColDesc(
    true,
    <DoubleLinedHeader
      primaryId="TelematicsList.cultivatedArea"
      secondaryId="TelematicsList.cultivatedPlot"
    />,
  ),
  distance: getColDesc(
    true,
    <FormattedMessage id="TelematicsList.traversedDistance" />,
  ),
  "machine.name": getColDesc(
    true,
    <DoubleLinedHeader
      primaryId="TelematicsList.machine"
      secondaryId="TelematicsList.machineLicencePlate"
    />,
  ),
  additionalEquipment: getColDesc(
    false,
    <DoubleLinedHeader
      primaryId="TelematicsList.additionalEquipment"
      secondaryId="TelematicsList.equipmentChipId"
    />,
  ),
};

const timeColumn = {
  FROM: getColDesc(
    true,
    <DoubleLinedHeader
      leftOffset
      primaryId="common.date"
      secondaryId="common.time"
    />,
  ),
};

const durationColumn = {
  FROM: getColDesc(
    true,
    <DoubleLinedHeader
      leftOffset
      primaryId="Telematics.logbook.duration"
      secondaryId="common.date"
    />,
  ),
};

export default TelematicsTable;
